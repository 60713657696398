<template>
<div class="fallback">123</div>
</template>

<script>
export default {
name: "Fallback",
components: {},
data() {
return {

};
},
computed: {},
watch: {},
methods: {},
created(){},
mounted(){},
beforeDestroy(){}
};
</script>

<style>
</style>